.unauthorized-page {
  position: fixed;
  top: 45%;
  left: 8%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
}

.content h2 {
  font-size: 24px;
  margin-bottom: 16px;
  color: red;
}

.content p {
  margin-bottom: 12px;
}

.content a {
  color: #007bff;
  text-decoration: none;
}

.content a:hover {
  text-decoration: underline;
}
