.tags-wrapper {
  background-color: var(--highlightslightpurple);
  color: var(--purple-600);
  padding: 5px 10px;
  border-radius: 5px;
  font-family: var(--caption-regular-font-family);
  font-size: var(--caption-regular-font-size);
  font-style: var(--caption-regular-font-style);
  font-weight: var(--caption-regular-font-weight);
  letter-spacing: var(--caption-regular-letter-spacing);
  line-height: var(--caption-regular-line-height);
  text-align: center;
}
