.scroll-container {
  position: relative;
  overflow: auto;
  height: calc(100vh - 30px); /* Adjust as needed */
  padding: 0 25px; /* Add padding to left and right sides */
}

.table-wrapper {
  overflow-x: auto;
  flex-grow: 1;
}

.horizontal-scrollbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.table-content {
  overflow-y: auto;
  height: 100%; /* Fill remaining height */
}

.table-content table {
  width: calc(100% - 4px); /* Reduce table width further */
  border-collapse: collapse; /* Ensure no space between table cells */
}

.table-content table th,
.table-content table td {
  padding: 6px; /* Further reduce padding for table cells */
  text-align: left; /* Adjust alignment as needed */
}

/* Adjust scrollbar styles if necessary */
.table-content::-webkit-scrollbar {
  width: 12px; /* Adjust scrollbar width if needed */
}

.table-content::-webkit-scrollbar-thumb {
  background-color: rgb(188, 184, 184);
  border-radius: 8px; /* Adjust scrollbar thumb radius if needed */
}

.table-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 8px; /* Adjust scrollbar track radius if needed */
}

.horizontal-scrollbar::-webkit-scrollbar {
  height: 12px;
}

.horizontal-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(188, 184, 184);
  border-radius: 10px;
}

.horizontal-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.table-content {
  scrollbar-color: rgb(188, 184, 184) #f1f1f1;
  scrollbar-width: thin;
}
