.result-time {
  color: var(--purple-600, #80628e);
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 100% */
}

.result-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.active-tab-border {
  border-bottom: 3px solid #0065ff;
}

.user-favoritie-bg {
  background: var(--primary-black, #23293b);
}

.hide-sidenav {
  position: relative;
  left: -100%;
}

.w-x {
  width: 0px !important;
}

.result-max-height {
  max-height: 1100px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.overflow-scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
}
