.switch-container {
  position: relative;
}

.switch-checkbox {
  display: none;
}

.switch-label {
  display: flex;
  align-items: center;
  background: #ccc;
  border-radius: 9999px;
  width: 50px;
  height: 30px;
  position: relative;
  cursor: pointer;
  transition: background 0.3s;
}

.switch-label .switch-text {
  position: absolute;
  top: 50%;
  width: 70%;
  text-align: center;
  color: #555;
  font-family: 'Poppins';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 100% */
  transform: translate(-50%, -50%);
}

.switch-label .switch-text.right {
  left: 100%;
}

.switch-label .switch-text.left {
  left: 0;
}

.slider {
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  transition: transform 0.3s;
}

.switch-checkbox:checked + .switch-label {
  background: linear-gradient(90deg, #4299e1, #7e5dd8);
}

.switch-checkbox:checked + .switch-label .slider {
  transform: translateX(100%);
}

.switch-checkbox:checked + .switch-label .switch-text.right {
  color: white;
}

.switch-checkbox:not(:checked) + .switch-label .switch-text.left {
  color: #555;
}

.font-size-container {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 2px;
  overflow: hidden;
  width: 50%;
}

.font-segment {
  flex: 1;
  flex-basis: 0;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-right: 1px solid #ccc;
}

.font-segment:hover {
  background-color: #f0f0f0;
}

.font-segment.active {
  background: linear-gradient(90deg, #4299e1, #7e5dd8);
  color: #ffffff;
}

.color-schema-container {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  width: 20%;
}

.color-schema-segment {
  flex: 1;
  flex-basis: 0;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-right: 1px solid #ccc;
}

.color-schema-segment:hover {
  background-color: #f0f0f0;
}

.color-schema-segment.active {
  background: linear-gradient(90deg, #4299e1, #7e5dd8);
  color: #ffffff;
}

.password-strength-container {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 2px;
  overflow: hidden;
  width: 30%;
}

.password-segment {
  flex: 1;
  flex-basis: 0;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-right: 1px solid #ccc;
}

.password-segment:hover {
  background-color: #f0f0f0;
}

.password-segment.active {
  background: linear-gradient(90deg, #4299e1, #7e5dd8);
  color: #ffffff;
}
.broder {
  border: 1px solid black;
  border-radius: 0.375rem;
  margin-bottom: 3rem;
}

.title-margin {
  margin-right: 20px; /* Adjust this value as needed */
}
