.secondary-button {
  align-items: flex-start;
  border: 1px solid;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.secondary-button .sign-in {
  font-family: var(--button-regular-medium-font-family);
  font-size: var(--button-regular-medium-font-size);
  font-style: var(--button-regular-medium-font-style);
  font-weight: var(--button-regular-medium-font-weight);
  letter-spacing: var(--button-regular-medium-letter-spacing);
  line-height: var(--button-regular-medium-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.secondary-button.state-disabled {
  border-color: var(--gray-200);
}

.secondary-button.state-rest {
  border-color: var(--primaryblue);
}

.secondary-button.state-hover {
  background-color: var(--highlightslightgreen);
  border-color: var(--primaryblue);
}

.secondary-button.state-disabled .sign-in {
  color: var(--gray-200);
}

.secondary-button.state-rest .sign-in {
  color: var(--primaryblue);
}

.secondary-button.state-hover .sign-in {
  color: var(--primaryblue);
}
