:root {
  --button-regular-medium-font-family: 'Poppins-SemiBold', Helvetica;
  --button-regular-medium-font-size: 16px;
  --button-regular-medium-font-style: normal;
  --button-regular-medium-font-weight: 600;
  --button-regular-medium-letter-spacing: 0px;
  --button-regular-medium-line-height: normal;
  --button-small-medium-font-family: 'Poppins-Medium', Helvetica;
  --button-small-medium-font-size: 13px;
  --button-small-medium-font-style: normal;
  --button-small-medium-font-weight: 500;
  --button-small-medium-letter-spacing: 0px;
  --button-small-medium-line-height: normal;
  --button-small-regular-font-family: 'Poppins-Regular', Helvetica;
  --button-small-regular-font-size: 13px;
  --button-small-regular-font-style: normal;
  --button-small-regular-font-weight: 400;
  --button-small-regular-letter-spacing: 0px;
  --button-small-regular-line-height: normal;
  --caption-regular-font-family: 'OpenSans-Regular', Helvetica;
  --caption-regular-font-size: 13px;
  --caption-regular-font-style: normal;
  --caption-regular-font-weight: 400;
  --caption-regular-letter-spacing: 0px;
  --caption-regular-line-height: normal;
  --gray-100: rgba(235, 238, 246, 1);
  --gray-200: rgba(225, 228, 236, 1);
  --gray-400: rgba(138, 145, 165, 1);
  --gray-50: rgba(250, 251, 253, 1);
  --h1-bold-font-family: 'Poppins-Bold', Helvetica;
  --h1-bold-font-size: 56px;
  --h1-bold-font-style: normal;
  --h1-bold-font-weight: 700;
  --h1-bold-letter-spacing: 0px;
  --h1-bold-line-height: 66px;
  --h4-medium-font-family: 'Poppins-Medium', Helvetica;
  --h4-medium-font-size: 16px;
  --h4-medium-font-style: normal;
  --h4-medium-font-weight: 500;
  --h3-medium-font-weight: 600;
  --h4-medium-letter-spacing: 0px;
  --h4-medium-line-height: 33px;
  --highlightslightblue: rgba(236, 241, 248, 1);
  --highlightslightgreen: rgba(214, 246, 249, 1);
  --highlightslightpurple: rgba(246, 235, 255, 1);
  --highlightslightred: rgba(255, 229, 227, 1);
  --highlightslightyellow: rgba(243, 246, 230, 1);
  --paragraph-large-regular-font-family: 'OpenSans-Regular', Helvetica;
  --paragraph-large-regular-font-size: 16px;
  --paragraph-large-regular-font-style: normal;
  --paragraph-large-regular-font-weight: 400;
  --paragraph-large-regular-letter-spacing: 0px;
  --paragraph-large-regular-line-height: 28px;
  --paragraph-small-regular-font-family: 'OpenSans-Regular', Helvetica;
  --paragraph-small-regular-font-size: 14px;
  --paragraph-small-regular-font-style: normal;
  --paragraph-small-regular-font-weight: 400;
  --paragraph-small-regular-letter-spacing: 0px;
  --paragraph-small-regular-line-height: 24px;
  --primaryblack: rgba(35, 41, 59, 1);
  --primaryblue: rgba(0, 101, 255, 1);
  --primarypink: rgba(187, 0, 255, 1);
  --primarywhite: rgba(255, 255, 255, 1);
  --purple-30: rgba(192, 152, 206, 1);
  --purple-600: rgba(128, 98, 142, 1);
}
