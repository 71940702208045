.main-button .get-assertions {
  color: #ffffff;
  font-family: var(--button-regular-medium-font-family);
  font-size: var(--button-regular-medium-font-size);
  font-style: var(--button-regular-medium-font-style);
  font-weight: var(--button-regular-medium-font-weight);
  letter-spacing: var(--button-regular-medium-letter-spacing);
  line-height: var(--button-regular-medium-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.main-button.disabled {
  background-color: var(--gray-200);
}

.main-button.hover {
  background-color: var(--primaryblue);
}
