.primary-button {
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 10px;
}

.primary-button .try-now {
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.primary-button.disabled {
  background-color: var(--gray-200);
}

.primary-button.small {
  padding: 10px 16px;
}

.primary-button.medium {
  padding: 10px 20px;
}

.primary-button.rest.boxed {
  background-color: var(--primaryblue);
}

.primary-button.hover.boxed {
  background-color: #2279ff;
}

.primary-button.hover.label-bold {
  background-color: #f9fafccc;
}

.primary-button.hover.label {
  background-color: #f9fafccc;
}

.primary-button.label-bold .try-now {
  color: var(--primaryblue);
}

.primary-button.medium .try-now {
  font-family: var(--button-regular-medium-font-family);
  font-size: var(--button-regular-medium-font-size);
  font-style: var(--button-regular-medium-font-style);
  font-weight: var(--button-regular-medium-font-weight);
  letter-spacing: var(--button-regular-medium-letter-spacing);
  line-height: var(--button-regular-medium-line-height);
}

.primary-button.boxed .try-now {
  color: #ffffff;
}

.primary-button.label .try-now {
  color: var(--purple-600);
}

.primary-button.small.label .try-now {
  font-family: var(--button-small-regular-font-family);
  font-size: var(--button-small-regular-font-size);
  font-style: var(--button-small-regular-font-style);
  font-weight: var(--button-small-regular-font-weight);
  letter-spacing: var(--button-small-regular-letter-spacing);
  line-height: var(--button-small-regular-line-height);
}

.primary-button.small.boxed .try-now {
  font-family: var(--button-small-medium-font-family);
  font-size: var(--button-small-medium-font-size);
  font-style: var(--button-small-medium-font-style);
  font-weight: var(--button-small-medium-font-weight);
  letter-spacing: var(--button-small-medium-letter-spacing);
  line-height: var(--button-small-medium-line-height);
}

.primary-button.small.label-bold .try-now {
  font-family: var(--button-small-medium-font-family);
  font-size: var(--button-small-medium-font-size);
  font-style: var(--button-small-medium-font-style);
  font-weight: var(--button-small-medium-font-weight);
  letter-spacing: var(--button-small-medium-letter-spacing);
  line-height: var(--button-small-medium-line-height);
}
