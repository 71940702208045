.landing {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.landing .div {
  background-color: #ffffff;
  border: 1px none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.bg-grey {
  background-color: #f0f1f9;
}

.landing .overlap {
  height: 2421px;
  position: absolute;
  top: 0;
  width: 100%;
}

.landing .img {
  height: 990px;
  position: absolute;
  top: 0;
  width: 100%;
}
.landing .base-lines {
  height: 185px;
  left: 0;
  bottom: 150px;
  position: absolute;
  width: 100%;
}
.main-content-container {
  min-height: 85vh;
  overflow-y: scroll;
}

.landing .rectangle {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(244, 240, 250, 0) 100%);
  height: 200px;
  width: 100%;
  border-bottom: 1px solid #e6e7e8;
}

.landing .rectangle-2 {
  background-color: #ffffff;
  height: 1258px;
  position: absolute;
  top: 989px;
  width: 100%;
}

.landing .ellipse {
  background-color: var(--primaryblue);
  border-radius: 504.5px;
  filter: blur(214px);
  height: 1009px;
  left: 40%;
  opacity: 0.05;
  position: absolute;
  top: 949px;
  width: 60%;
}

.landing .ellipse-2 {
  background-color: var(--primaryblue);
  border-radius: 13px;
  filter: blur(14px);
  height: 26px;
  left: 720px;
  opacity: 0.1;
  position: absolute;
  top: 1276px;
  width: 26px;
}

.landing .ellipse-3 {
  background-color: var(--primarypink);
  border-radius: 30px;
  filter: blur(34px);
  height: 60px;
  left: 42%;
  opacity: 0.5;
  position: relative;
  width: 60px;
  top: 300px;
}

.landing .ellipse-4 {
  background-color: #ff948d;
  border-radius: 297px;
  filter: blur(84px);
  height: 594px;
  left: 101px;
  opacity: 0.15;
  position: absolute;
  top: 1004px;
  width: 594px;
}

.landing .rectangle-3 {
  background-color: #e6e7e8;
  height: 1px;
  left: 10%;
  position: absolute;
  top: 937px;
  width: 80%;
}

.landing .text-wrapper {
  color: var(--primaryblack);
  font-family: var(--paragraph-large-regular-font-family);
  font-size: var(--paragraph-large-regular-font-size);
  font-style: var(--paragraph-large-regular-font-style);
  font-weight: var(--paragraph-large-regular-font-weight);
  letter-spacing: var(--paragraph-large-regular-letter-spacing);
  line-height: var(--paragraph-large-regular-line-height);
  text-align: center;
  max-width: 70%;
}

.landing .assess-the-origin-of {
  color: transparent;
  font-family: var(--h1-bold-font-family);
  font-size: var(--h1-bold-font-size);
  font-style: var(--h1-bold-font-style);
  font-weight: var(--h1-bold-font-weight);
  letter-spacing: var(--h1-bold-letter-spacing);
  line-height: var(--h1-bold-line-height);
  position: relative;
  text-align: center;
  top: 30px;
}

.landing .span {
  color: #23293b;
  font-family: var(--h1-bold-font-family);
  font-size: var(--h1-bold-font-size);
  font-style: var(--h1-bold-font-style);
  font-weight: var(--h1-bold-font-weight);
  letter-spacing: var(--h1-bold-letter-spacing);
  line-height: var(--h1-bold-line-height);
}

.landing .text-wrapper-2 {
  color: #0064ff;
  font-family: var(--h1-bold-font-family);
  font-size: var(--h1-bold-font-size);
  font-style: var(--h1-bold-font-style);
  font-weight: var(--h1-bold-font-weight);
  letter-spacing: var(--h1-bold-letter-spacing);
  line-height: var(--h1-bold-line-height);
}

.landing .rectangle-4 {
  background-color: var(--primaryblue);
  height: 50px;
  left: 1206px;
  position: absolute;
  top: 228px;
  width: 3px;
}

.landing .container-how-works {
  min-width: 365px;
  padding: 40px;
}

.landing .container-how-works-overlay-bg {
  background-color: #ff948d;
  border-radius: 297px;
  filter: blur(84px);
  height: 500px;
  opacity: 0.15;
  position: absolute;
  width: 500px;
}

.landing .container-how-works-overlay .box {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
}

.landing .container-how-works-overlay .ellipse-wrapper {
  border: 0px none;
  height: 450px;
  width: 450px;
}
.landing .content-preview-container {
  background-color: var(--primaryblue);
  border-radius: 504.5px;
  filter: blur(214px);
  height: 800px;
  opacity: 0.05;
  position: absolute;
  top: 0;
  width: 1009px;
}

.landing .text-wrapper-3 {
  color: var(--primaryblue);
  font-family: var(--h1-bold-font-family);
  font-size: var(--h1-bold-font-size);
  font-style: var(--h1-bold-font-style);
  font-weight: var(--h1-bold-font-weight);
  letter-spacing: var(--h1-bold-letter-spacing);
  line-height: var(--h1-bold-line-height);
  position: relative;
}

.landing .ellipse-5 {
  background-color: var(--primaryblack);
  border-radius: 138.5px;
  filter: blur(154px);
  height: 277px;
  left: 1018px;
  opacity: 0.1;
  position: absolute;
  top: 1135px;
  width: 277px;
}

.landing .rectangle-5 {
  -webkit-backdrop-filter: blur(8px) brightness(100%);
  backdrop-filter: blur(8px) brightness(100%);
  background-color: #ffffffb2;
  border: 1px solid;
  border-color: transparent;
  border-radius: 12px;
  min-height: 500px;
  height: auto;
  /* max-width: 90%; */
  padding-left: 80px;
}

.landing .group-wrapper {
  -webkit-backdrop-filter: blur(8px) brightness(100%);
  backdrop-filter: blur(8px) brightness(100%);
  background-color: #ffffffb2;
  border-radius: 12px;
  overflow: hidden;
  left: 10%;
  position: relative;
  width: 80%;
}

.landing .group-2 {
  min-height: 300px;
  position: relative;
  top: 40px;
  overflow: hidden;
}
.hh-5 {
  height: 600px;
}

.w-95 {
  width: 95%;
}

.landing .text-wrapper-4 {
  color: #80628e;
  font-family: 'Open Sans', Helvetica;
  font-size: 13px;
  font-weight: 400;
  left: 104px;
  letter-spacing: 0;
  line-height: 13px;
  position: absolute;
  top: 67px;
  white-space: nowrap;
}

.overlap-group-2 {
  min-height: 37px;
  width: 100%;
}

.frame {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--gray-100);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  gap: 7px;
  left: 4px;
  padding: 2px 14px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.landing .ellipse-6 {
  background-color: var(--highlightslightred);
  border: 1px solid;
  border-color: #0000000d;
  border-radius: 10.5px;
  height: 21px;
  min-width: 21px;
  position: relative;
}

.landing .text-wrapper-5 {
  color: var(--primaryblack);
  font-family: 'Inter', Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 33px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .frame-2 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--gray-100);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  gap: 7px;
  left: 73px;
  padding: 2px 14px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.landing .ellipse-7 {
  background-color: var(--highlightslightpurple);
  border: 1px solid;
  border-color: #0000000d;
  border-radius: 10.5px;
  height: 21px;
  min-width: 21px;
  position: relative;
}

.landing .frame-3 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--gray-100);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  gap: 7px;
  left: 143px;
  padding: 2px 14px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.landing .ellipse-8 {
  background-color: var(--highlightslightblue);
  border: 1px solid;
  border-color: #0000000d;
  border-radius: 10.5px;
  height: 21px;
  min-width: 21px;
  position: relative;
}

.landing .frame-4 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--gray-100);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  gap: 7px;
  left: 213px;
  padding: 2px 14px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.landing .ellipse-9 {
  background-color: var(--highlightslightyellow);
  border: 1px solid;
  border-color: #0000000d;
  border-radius: 10.5px;
  height: 21px;
  min-width: 21px;
  position: relative;
}

.landing .rectangle-6 {
  background-color: var(--gray-100);
  height: 1px;
  left: 0;
  position: absolute;
  top: 41px;
  width: 99%;
}

.landing .overlap-2 {
  height: 26px;
  left: 0;
  margin-top: 30px;
  width: 100%;
}

.landing .group-3 {
  height: 26px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.landing .rectangle-7 {
  background-color: var(--gray-100);
  height: 1px;
  width: 100%;
}

.landing .source {
  color: var(--gray-400);
  font-family: 'Inter', Helvetica;
  font-size: 12px;
  font-weight: 600;
  left: 21px;
  letter-spacing: 0;
  line-height: 13px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 51px;
}

.landing .assertion-warpper {
  color: var(--gray-400);
  font-family: 'Inter', Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 13px;
  white-space: nowrap;
}

.landing .text-wrapper-7 {
  color: var(--gray-400);
  font-family: 'Inter', Helvetica;
  font-size: 12px;
  font-weight: 600;
  left: 479px;
  letter-spacing: 0;
  line-height: 13px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 33px;
}

.landing .text-wrapper-8 {
  color: var(--gray-400);
  font-family: 'Inter', Helvetica;
  font-size: 12px;
  font-weight: 600;
  left: 587px;
  letter-spacing: 0;
  line-height: 13px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 56px;
}

.landing .text-wrapper-9 {
  color: var(--gray-400);
  font-family: 'Inter', Helvetica;
  font-size: 12px;
  font-weight: 600;
  left: 881px;
  letter-spacing: 0;
  line-height: 13px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 43px;
}

.landing .info-icon {
  color: var(--gray-400);
  font-family: 'Font Awesome 6 Free-Solid', Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  margin-left: 4px;
}

.landing .p-wrapper {
  align-items: flex-start;
  background-color: var(--highlightslightgreen);
  border-radius: 4px;
  display: flex;
  gap: 10px;
  overflow: hidden;
  padding: 8px;
  width: fit-content;
}

.landing .p {
  color: var(--primaryblack);
  font-family: 'Inter', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .frame-5 {
  align-items: center;
  background-color: #ffffff;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(0, 100.74, 255), rgb(230, 231, 232)) 1;
  border-left-style: solid;
  border-left-width: 1px;
  border-radius: 4px 4px 0px 0px;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  box-shadow: 0px -3px 3px #0084ff0f;
  display: flex;
  gap: 7px;
  left: 0;
  padding: 2px 14px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.landing .ellipse-10 {
  background-color: var(--highlightslightgreen);
  border: 1px solid;
  border-color: #0000000d;
  border-radius: 10.5px;
  height: 21px;
  min-width: 21px;
  position: relative;
}

.landing .text-wrapper-11 {
  color: var(--primaryblack);
  font-family: 'Poppins', Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 33px;
  position: relative;
  white-space: nowrap;
}

.landing .assertion-table-row {
  align-items: flex-start;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-100);
  display: flex;
  left: 0;
  padding: 8px 0px;
  width: 100%;
}

.landing .text-wrapper-12 {
  color: var(--gray-400);
  font-family: 'Font Awesome 6 Free-Solid', Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  min-width: 20px;
}

.landing .div-2 {
  font-family: 'Inter', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  width: 100%;
}

.landing .text-wrapper-13 {
  color: #23293b;
  line-height: 24px;
}

.landing .text-wrapper-14 {
  color: #8991a4;
  font-size: 12px;
  line-height: 19px;
  width: 100%;
  min-width: 100px;
  max-width: 200px;
  /* text-overflow: ellipsis; */
}
.landing .url-text {
  overflow-wrap: break-word;
}

.landing .text-wrapper-15 {
  color: var(--gray-400);
  font-family: 'Font Awesome 6 Free-Solid', Helvetica;
}

.landing .text-wrapper-16 {
  color: var(--primaryblue);
  font-family: 'Inter', Helvetica;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.landing .text-wrapper-17 {
  color: var(--purple-600);
  font-family: 'Inter', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.landing .text-wrapper-18 {
  color: var(--purple-600);
  font-family: 'Inter', Helvetica;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.text-wrapper-18::before {
  content: attr(data-text);
}

.text-wrapper-18[data-text]:hover::before {
  content: none;
}

.landing .frame-10 {
  background-color: var(--gray-100);
  border-radius: 4px;
  gap: 9px;
  padding: 8px 9px;
  position: relative;
  width: fit-content;
}


.landing .text-wrapper-19 {
  color: var(--gray-400);
  font-family: 'Inter', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .frame-11 {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-100);
  display: flex;
  left: 0;
  padding: 8px 12px;
  position: absolute;
  top: 294px;
  width: 100%;
}

.landing .frame-12 {
  align-items: flex-start;
  background-color: var(--gray-50);
  border-radius: 4px;
  display: flex;
  gap: 9px;
  padding: 8px 9px;
  position: relative;
  width: fit-content;
}

.landing .frame-13 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  gap: 9px;
  padding: 8px 9px;
  position: relative;
  width: 266px;
}

.landing .frame-14 {
  justify-content: space-between;
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-100);
  display: flex;
  left: 0;
  padding: 8px 12px;
  position: absolute;
  top: 399px;
  width: 100%;
}

.landing .group-5 {
  height: 42px;
  min-width: 218px;
  position: relative;
}

.landing .text-wrapper-20 {
  color: var(--primaryblue);
  font-family: 'Inter', Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 0;
  width: 216px;
}

.landing .text-wrapper-21 {
  color: #663e79;
  margin-top: 30px;
  font-family: var(--paragraph-large-regular-font-family);
  font-size: var(--paragraph-large-regular-font-size);
  font-style: var(--paragraph-large-regular-font-style);
  font-weight: var(--paragraph-large-regular-font-weight);
  letter-spacing: var(--paragraph-large-regular-letter-spacing);
  line-height: var(--paragraph-large-regular-line-height);
  position: relative;
}

.landing .design-component-instance-node {
  left: 231px !important;
  position: absolute !important;
  top: 1435px !important;
}

.landing .secondary-button-instance {
  left: 352px !important;
  position: absolute !important;
  top: 1435px !important;
}

.landing .text-wrapper-22 {
  color: #663e79;
  font-family: var(--paragraph-large-regular-font-family);
  font-size: var(--paragraph-large-regular-font-size);
  font-style: var(--paragraph-large-regular-font-style);
  font-weight: var(--paragraph-large-regular-font-weight);
  letter-spacing: var(--paragraph-large-regular-letter-spacing);
  line-height: var(--paragraph-large-regular-line-height);
  position: relative;
  text-align: center;
  top: 1500px;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

.landing .group-6 {
  height: 90%;
  left: 874px;
  /* width: 90%; */
}
main {
  min-height: 85vh;
}
.landing .overlap-3 {
  height: 90%;
  position: relative;
  margin-top: 20px;
  width: 100%;
}

.landing .rectangle-8 {
  background-color: #d6f5f8;
  height: 25px;
  left: 0;
  position: relative;
  top: 16px;
  width: 384px;
}

.landing .rectangle-9 {
  background-color: #ffe5e3;
  height: 25px;
  left: 416px;
  position: relative;
  top: 16px;
  width: 502px;
}

.landing .rectangle-10 {
  background-color: var(--highlightslightpurple);
  height: 25px;
  left: 90px;
  position: relative;
  top: 53px;
  width: 858px;
}

.landing .rectangle-11 {
  background-color: #f6eaff;
  height: 25px;
  left: 0;
  position: absolute;
  top: 92px;
  width: 185px;
}

.landing .rectangle-12 {
  background-color: #ecf0f7;
  height: 25px;
  left: 735px;
  position: absolute;
  top: 92px;
  width: 212px;
}

.landing .rectangle-13 {
  background-color: var(--highlightslightblue);
  height: 25px;
  left: 0;
  position: absolute;
  top: 128px;
  width: 554px;
}

.landing .rectangle-14 {
  background-color: var(--highlightslightyellow);
  height: 25px;
  left: 67px;
  position: absolute;
  top: 203px;
  width: 878px;
}

.landing .rectangle-15 {
  background-color: #f6f9ec;
  height: 25px;
  left: 1px;
  position: absolute;
  top: 243px;
  width: 175px;
}

.landing .text-wrapper-23 {
  color: var(--primaryblack);
  font-family: 'Open Sans', Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 4px;
  letter-spacing: 0;
  line-height: 53px;
  position: relative;
  top: 8px;
}

.landing .overlap-group-wrapper {
  height: 20px;
  left: 364px;
  position: absolute;
  top: 0;
  width: 22px;
}

.landing .overlap-group-3 {
  background-color: #d6f6f9;
  height: 20px;
  position: relative;
  width: 20px;
}

.landing .text-wrapper-24 {
  color: #000000;
  font-family: 'Inter', Helvetica;
  font-size: 10px;
  font-weight: 700;
  left: 7px;
  letter-spacing: 0;
  line-height: 13px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
}

.landing .overlap-wrapper {
  height: 20px;
  left: 898px;
  position: absolute;
  top: 0;
  width: 22px;
}

.landing .overlap-4 {
  background-color: #ffe5e3;
  height: 20px;
  position: relative;
  width: 20px;
}

.landing .group-7 {
  height: 20px;
  left: 165px;
  position: absolute;
  top: 76px;
  width: 22px;
}

.landing .overlap-5 {
  background-color: #f6ebff;
  height: 20px;
  position: relative;
  width: 20px;
}

.landing .group-8 {
  height: 20px;
  left: 534px;
  position: absolute;
  top: 113px;
  width: 22px;
}

.landing .overlap-6 {
  background-color: #ecf1f8;
  height: 20px;
  position: relative;
  width: 20px;
}

.landing .group-9 {
  height: 20px;
  left: 156px;
  position: absolute;
  top: 227px;
  width: 22px;
}

.landing .overlap-7 {
  background-color: #f7faec;
  height: 20px;
  position: relative;
  width: 20px;
}

.landing .text-wrapper-25 {
  color: var(--primaryblack);
  font-family: 'Poppins', Helvetica;
  font-size: 16px;
  font-weight: 700;
  min-width: 250px;
  left: 1px;
  letter-spacing: 0;
  line-height: 33px;
  position: relative;
  top: 0;
  white-space: nowrap;
}

.input-action-item {
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  left: 212px;
  padding: 8px 12px;
  width: fit-content;
}

.input-action-item:hover {
  background-color: #f6f9ec;
  cursor: pointer;
}

.primary-Button:hover{
  background-color: #f6f9ec;
  cursor: pointer;

}

.landing .text-wrapper-26 {
  color: var(--purple-30);
  font-family: 'Font Awesome 6 Pro-Solid', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.landing .text-wrapper-27 {
  color: var(--purple-30);
  font-family: 'Open Sans', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-primary {
  color: #0065ff !important;
}

.landing .frame-16 {
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  left: 841px;
  padding: 8px 12px;
  position: absolute;
  top: 334px;
  width: fit-content;
}

.landing .frame-17 {
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  left: 115px;
  padding: 8px 12px;
  position: absolute;
  top: 333px;
  width: fit-content;
}

.landing .text-wrapper-28 {
  color: var(--purple-30);
  font-family: 'Font Awesome 6 Pro-Solid', Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-29 {
  color: var(--purple-30);
  font-family: 'Open Sans', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .frame-18 {
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  left: 0;
  padding: 8px 12px;
  position: absolute;
  top: 332px;
  width: fit-content;
}

.landing .text-wrapper-30 {
  color: var(--purple-30);
  font-family: 'Font Awesome 6 Pro-Solid', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.landing .rectangle-16 {
  background-color: var(--gray-100);
  height: 1px;
  left: 0;
  margin-top: 10px;
  position: relative;
  width: 100%;
}

.landing .text-wrapper-31 {
  color: #80628e;
  font-family: 'Open Sans', Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 13px;
  position: relative;
  top: 9px;
  white-space: nowrap;
}

.landing .custom-nav-tab {
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  gap: 7px;
  left: 73px;
  padding: 2px 14px;
  top: 0;
  width: -moz-fit-content;
  width: fit-content;
}

.nav-border {
  border: 1px solid var(--gray-100);
}

.nav-border-active {
  border: 1px solid var(--primaryblue);
  border-bottom: unset !important;
}

.landing .rectangle-17 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  height: 225px;
  position: relative;
  bottom: 185px;
  width: 100%;
}

.landing .group-10 {
  height: 185px;
  left: 0;
  position: absolute;
  width: 100%;
}

.landing .overlap-8 {
  height: 185px;
  position: relative;
  width: 100%;
}

.landing .group-11 {
  height: 185px;
  min-height: 185px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.landing .union-wrapper {
  min-height: 142px;
  height: auto;
  left: 0;
  position: relative;
  top: 16px;
  width: 100%;
}

.landing .union {
  min-height: 142px;
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.landing .group-12 {
  height: 77px;
  margin-top: 200px;
  justify-content: center;
  display: flex;
}

.landing .submit-action {
  width: 65%;
  margin-left: 18%;
}

.landing .overlap-9 {
  height: 77px;
  width: 155px;
  position: relative;
}

.landing .rectangle-18 {
  background: linear-gradient(180deg, rgba(17.69, 220.29, 235.88, 0.3) 0%, rgba(23.11, 225.61, 241.19, 0) 100%);
  border-radius: 10px;
  filter: blur(15px);
  height: 42px;
  left: 0;
  position: absolute;
  top: 35px;
  width: 130px;
}

.landing .frame-19 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(81.28, 207, 216.75) 0%, rgb(70, 186, 195) 100%);
  border: 0.5px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9)) 1;
  border-radius: 4px;
  box-shadow: 0px 0px 4px #ffffff52;
  display: flex;
  gap: 10px;
  left: 2px;
  padding: 6px 12px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.landing .text-wrapper-32 {
  color: #000000;
  font-family: var(--h1-bold-font-family);
  font-size: var(--h4-medium-font-size);
  font-style: var(--h4-medium-font-style);
  font-weight: var(--h3-medium-font-weight);
  letter-spacing: var(--h4-medium-letter-spacing);
  line-height: var(--h4-medium-line-height);
  margin-top: -0.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.landing .frame-20 {
  align-items: flex-start;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  left: 127px;
  padding: 6px 12px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.landing .text-wrapper-33 {
  color: #000000;
  font-family: var(--h1-bold-font-family);
  font-size: var(--h4-medium-font-size);
  font-style: var(--h4-medium-font-style);
  font-weight: var(--h3-medium-font-weight);
  letter-spacing: var(--h4-medium-letter-spacing);
  line-height: var(--h4-medium-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.landing .overlap-10 {
  height: 77px;
  position: relative;
  width: 88px;
}

.landing .rectangle-19 {
  background: linear-gradient(180deg, rgba(243.31, 158.77, 153.08, 0.3) 0%, rgba(243, 159, 153, 0) 100%);
  border-radius: 10px;
  filter: blur(15px);
  height: 42px;
  left: 0;
  position: absolute;
  top: 35px;
  width: 88px;
}

.landing .frame-21 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(255, 167.24, 161.5) 0%, rgb(255, 148, 141) 100%);
  border: 0.5px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9)) 1;
  border-radius: 4px;
  box-shadow: 0px 0px 4px #ffffff52;
  display: flex;
  gap: 10px;
  left: 0;
  padding: 6px 12px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.landing .overlap-11 {
  height: 77px;
  position: relative;
  width: 154px;
}

.landing .rectangle-20 {
  background: linear-gradient(180deg, rgba(207.33, 150.85, 249.69, 0.3) 0%, rgba(207, 151, 250, 0) 100%);
  border-radius: 10px;
  filter: blur(15px);
  height: 42px;
  left: 0;
  position: absolute;
  top: 35px;
  width: 154px;
}

.landing .frame-22 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(199.93, 131.64, 250.75) 0%, rgb(179, 93, 243) 100%);
  border: 0.5px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9)) 1;
  border-radius: 4px;
  box-shadow: 0px 0px 4px #ffffff52;
  display: flex;
  gap: 10px;
  left: 0;
  padding: 6px 12px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.landing .frame-23 {
  align-items: flex-start;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  position: relative;
  padding: 6px 12px;
  width: fit-content;
}

.landing .main-button-instance {
  left: 1290px !important;
  position: absolute !important;
  top: 655px !important;
}

.landing .main-button-2 {
  text-align: right !important;
}

.landing .text-wrapper-34 {
  color: var(--purple-600);
  font-family: var(--paragraph-small-regular-font-family);
  font-size: var(--paragraph-small-regular-font-size);
  font-style: var(--paragraph-small-regular-font-style);
  font-weight: var(--paragraph-small-regular-font-weight);
  letter-spacing: var(--paragraph-small-regular-letter-spacing);
  line-height: var(--paragraph-small-regular-line-height);
  position: relative;
  text-align: right;
}

::-webkit-scrollbar {
  display: none;
}

.landing .content-input {
  align-items: flex-start;
  border: 2px solid #0d99ff !important;
  border-radius: 12px;
  display: flex;
  min-height: 200px;
  overflow-y: scroll;
}

.landing .text-wrapper-35 {
  color: var(--primaryblack);
  font-family: var(--paragraph-small-regular-font-family);
  font-size: var(--paragraph-small-regular-font-size);
  font-style: var(--paragraph-small-regular-font-style);
  font-weight: var(--paragraph-small-regular-font-weight);
  letter-spacing: var(--paragraph-small-regular-letter-spacing);
  line-height: var(--paragraph-small-regular-line-height);
  margin-top: -2px;
  position: relative;
}

.landing .header-instance {
  z-index: 99999;
  position: absolute !important;
  top: 0 !important;
}

.landing .footer-instance {
  border-bottom-style: none !important;
  border-left-style: none !important;
  border-right-style: none !important;
  left: 0 !important;
  bottom: 0px !important;
}

.pill-button {
  position: relative;
  border-radius: 50px;
  background-color: #f6ebff;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 4px 16px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #0065ff;
  font-family: Poppins;
}

.pill-button-text {
  position: relative;
  line-height: 20px;
}
.bold-text-message {
  position: relative;
  font-size: 18px;
  line-height: 33px;
  font-family: Poppins;
  color: #23293b;
  text-align: left;
}
