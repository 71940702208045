.footer {
  background-color: #ffffff;
  border-color: #e1e3eb;
  border-top-style: solid;
  border-top-width: 0.5px;
  height: 91px;
  position: relative;
  width: 100%;
}

.footer .group {
  height: 46px;
  margin-left: 24px;
  width: 172px;
}

.ml-100 {
  margin-left: 100px !important;
}

.block,
.group:hover {
  cursor: pointer;
}

.footer .block {
  color: var(--gray-400);
  font-family: var(--caption-regular-font-family);
  font-size: var(--caption-regular-font-size);
  font-style: var(--caption-regular-font-style);
  font-weight: var(--caption-regular-font-weight);
  letter-spacing: var(--caption-regular-letter-spacing);
  line-height: var(--caption-regular-line-height);
}
