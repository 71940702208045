@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
	body {
		margin: 0 !important;
	}
}

.main-container {
	--ckeditor5-preview-height: 700px;
	font-family: 'Lato';
	margin-left: auto;
	margin-right: auto;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container__editor-wrapper {
	display: flex;
}

.editor-container_document-editor {
	border: 1px solid var(--ck-color-base-border);
}

.editor-container_document-editor .editor-container__toolbar {
	display: flex;
	position: relative;
	box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
}

.editor-container_document-editor .editor-container__toolbar > .ck.ck-toolbar {
	flex-grow: 1;
	width: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border-top: 0;
	border-left: 0;
	border-right: 0;
}

.editor-container_document-editor .editor-container__menu-bar > .ck.ck-menu-bar {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border-top: 0;
	border-left: 0;
	border-right: 0;
}

.editor-container_document-editor .editor-container__editor-wrapper {
	max-height: var(--ckeditor5-preview-height);
	min-height: var(--ckeditor5-preview-height);
	overflow-y: scroll;
	background: var(--ck-color-base-foreground);
    width: 100%;
}

.editor-container_document-editor .editor-container__editor {
	margin-top: 28px;
	margin-bottom: 28px;
	height: 100%;
    width: 100%; 
    max-width: 100%; 
}

.editor-container_document-editor .editor-container__editor .ck.ck-editor__editable {
    box-sizing: border-box;
    min-width: 100%;
    min-height: 297mm;
    height: fit-content;
    padding: 20mm 12mm;
    border: 1px hsl(0, 0%, 82.7%) solid;
    background: hsl(0, 0%, 100%);
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
    flex: 1 1 auto;
    margin-left: auto; 
    margin-right: auto; 
}
