.description-container {
  position: relative;
  display: inline-block;
}

.description-text {
  position: fixed; /* Use fixed positioning */
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  z-index: 1000; /* Ensure it appears above other elements */
  min-width: 300px;
  max-width: 500px;
  max-height: 150px;
  overflow-y: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  line-height: 1.5;
  color: #333;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}
