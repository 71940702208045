@import './themeguide.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

[multiple],
[type='date'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
select,
textarea {
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}
@keyframes outline {
  from {
    stroke-dasharray: 0, 345.576px;
  }
  to {
    stroke-dasharray: 345.576px, 345.576px;
  }
}

#outline {
  animation: .38s ease-in outline;
  transform: rotate(0deg);
  transform-origin: center;
}

@keyframes circle {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

#white-circle {
  animation: .35s ease-in .35s forwards circle;
  transform: none;
  transform-origin: center;
}

@keyframes check {
  from {
    stroke-dasharray: 0, 75px;
  }
  to {
    stroke-dasharray: 75px, 75px;
  }
}

#check {
  animation: .34s cubic-bezier(0.65, 0, 1, 1) .8s forwards check; 
  stroke-dasharray: 0, 75px;
}

@keyframes check-group {
  from {
    transform: scale(1);
  }
  
  50% {
    transform: scale(1.09);
  }
  
  to {
    transform: scale(1);
  }
}

#check-group {
  animation: .32s ease-in-out 1.03s check-group;
  transform-origin: center;
}

.collapsible-section {
  @apply overflow-hidden transition-height duration-500 ease-in-out;
  max-height: 0;
}
@media (max-width: 640px) {
  .responsive-text {
    font-size: 0.875rem; /* Tailwind class text-sm */
  }
  .responsive-padding {
    padding: 0.5rem; /* Tailwind class p-2 */
  }
}
.cm-cursor{
  background-color: #9333ea !important;
  display: inline-block !important;
  color: black !important;
  width: 1px !important;
  z-index: 151
}
.cm-editor { border: 2px solid #9333ea }
.cm-editor.cm-focused { border: 2px solid blue }

.collapsible-section.open {
  max-height: 1200px; /* you might need to adjust this value */
}
.profile-image-man {
  background-image: url('./static/img/man-user.png');
  background-size: cover;
  background-position: center;
}

.profile-image-woman {
  background-image: url('./static/img/woman-user.png');
  background-size: cover;
  background-position: center;
}

.text-upper {
  text-transform: uppercase;
}

.cm-editor {
  border-radius: 12px;
  padding: 10px;
}
.support-text {
  color: var(--purple-30);
  font-family: 'Open Sans', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.result-scroll * {
  /* scrollbar-width: thin; */
  /* scrollbar-color: var(--primary-black, #23293B) var(--primary-black, #23293B); */
  /* max-height: 1220px; */
  /* overflow-y: scroll; */
}
/* 
.border{
 border: 1px solid rgb(229, 231, 235) !important
} */

.overflow-visible {
  overflow: visible !important;
}
.h-p-4 {
  height: 4px;
}

.bg-light-dark {
  background: #2f364d;
}

.app-scroll-bar::-webkit-scrollbar {
  width: 12px !important;
}

.app-scroll-bar::-webkit-scrollbar-track {
  background: linear-gradient(to bottom, #9333ea, #2563eb) !important; /* Use your desired gradient colors here */
}
.corporation-button {
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
}
.corporation-button:hover {
  background-color: #5b67d0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.app-scroll-bar::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #9333ea, #2563eb) !important; /* Use your desired gradient colors here */
  border-radius: 6px;
}

.animte-size {
  transition:
    width 0.3s ease,
    height 0.3s ease,
    opacity 0.3s ease !important;
}

.half-background {
  background: linear-gradient(to bottom, #2563eb 50%, #ffffff 50%);
}
